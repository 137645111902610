import Alpine from 'alpinejs'
import { noop } from 'helpers/util'

export default (async function () {
  Alpine.data('lightbox', function (items = []) {
    const wrapper = document.getElementById('lightbox-wrapper')
    let [PhotoSwipe, PhotoSwipeUI_Default] = [noop, noop]

    return {
      items,
      instance: null,
      openLightbox(index = 0) {
        const options = { index }
        if (PhotoSwipe === noop) return

        this.instance = new PhotoSwipe(
          wrapper,
          PhotoSwipeUI_Default,
          this.items,
          options
        )
        this.instance.init()
      },
      async init() {
        import('photoSwipe/dist/default-skin/default-skin.css')
        import('photoSwipe/dist/photoswipe.css')

        ;[PhotoSwipe, PhotoSwipeUI_Default] = await Promise.all([
          import('photoswipe').then(imported => imported.default),
          import('photoSwipe/dist/photoswipe-ui-default').then(imported => imported.default),
        ])
      },
    }
  })
})()
