import Alpine from 'alpinejs'
import { FETCH_CONFIG } from 'config/constants'
import { noop } from 'helpers/util'

export default (async function () {
  const location = document.location

  Alpine.data('modal', function () {
    let scrollRef = this.$refs.scrollContainer
    let { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } = {
      disableBodyScroll: noop, enableBodyScroll: noop, clearAllBodyScrollLocks: noop
    }

    ;(async () => {
      ;({ disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } =
        await import('body-scroll-lock'))
    })()

    return {
      content: null,
      loading: false,
      bodyShouldScroll: true,
      close() {
        this.content = null
        this.loading = false
      },
      toggleBodyScroll(enable = true) {
        if (this.bodyShouldScroll === enable) return

        this.bodyShouldScroll = enable
        enable ? enableBodyScroll(scrollRef) : disableBodyScroll(scrollRef, { reserveScrollBarGap: true })
      },
      modalListener(e) {
        const url = new URL(e.detail, location)

        // prevent attacks
        if (!(location.hostname === url.hostname || !url.hostname.length))
          return

        this.loading = true

        url.searchParams.append('_ajax', true)

        fetch(url.href, FETCH_CONFIG)
          .then(async (resp) => {
            if (!resp.ok) throw new Error('Failed loading', url.href)
            return resp.text()
          })
          .then((content) => {
            if (content) {
              this.content = content
            }
            this.loading = false
          })
          .catch((e) => {
            console.error(e)
            this.loading = false
          })
      },
    }
  })
})()
